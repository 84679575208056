import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import Scrollbar from 'smooth-scrollbar-react';
import { Link } from "react-router-dom";
import Page from '../components/page';
import '../css/como_jogar.css';
import types from '../constants/action-types';


function mapStateToProps (state) {
    return {
        anwsers: state.anwsers,
        test: state.test,
        subject: state.subject,
        subjects: state.subjects,
        cases_id_list: state.cases_id_list
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onResetClick: id => {
            dispatch({ type: types.RESET })
            return Promise.resolve();
        },
        onSubjectClick: id => {
            let subject = id;
            dispatch({ type: types.SET_SUBJECT, payload: subject });
            return Promise.resolve();
        }
    }
}

class ComoJogar extends Page {

    constructor (props) {
        super(props);
        this.id = 'como_jogar';
        this.selectSubject = this.selectSubject.bind(this);
    }

    selectSubject (e) {
        // let enabled = !e.currentTarget.classList.contains('disabled');
        let selectedSubject = e.currentTarget.attributes.rel.value;

        // if (enabled) {
        this.props.onResetClick().then(() => {
            this.props.onSubjectClick(selectedSubject).then(() => {
                    setTimeout(() => {
                        this.setState({ disabled: false });
                        this.props.history.replace('/caso');
                    }, 550);
                });
        });
        // }
    }

    render () {
        const isMobile = window.innerWidth < 720;

        let scroll =
            <div className="list-data" style={{ height: '100%', width: '100%', overflow: 'auto' }}>
                <p>Seu objetivo é desvendar cinco casos fictícios que ofereceram riscos à segurança das informações do Senac DN.</p>
                <p>Leia com atenção cada caso e, em seguida, clique em <span className="button-play button-pistas">ver pistas</span></p>
                <p>Cada carta contém uma pergunta. Para ler a resposta, clique na carta.</p>
                <p>Quando você estiver certo da resposta, clique em <span className="button-play button-submit">responder</span></p>
                <p>Para cada caso, são apresentadas três opções de resposta.</p>
                <p>Marque a resposta correta e, em seguida, escolha o personagem responsável.</p>
                <p>Clique em <span className="button-play button-submit">confirmar</span></p>

                <h2><strong>Pontuação</strong></h2>
                <p>Após responder o 5º caso, será apresentada a sua pontuação.</p>
                <p>A pontuação leva em conta os acertos e o tempo gasto na jogada.</p>
                <p>Quanto mais respostas você acertar e mais rápida for sua jogada, maior será sua pontuação.</p>

                <h2><strong>Atenção</strong></h2>
                <p>Você tem até 15 minutos para concluir todos os casos.</p>
                <p>Após 15 minutos, sua jogada será concluída, mesmo se algum caso ainda estiver pendente de solução.</p>
                <p>Você poderá jogar quantas vezes quiser.</p>
                <p>Sua posição no ranking será o seu melhor resultado.</p>
                <p>Os melhores colocados no ranking receberão prêmios.</p>
                <p>Caso dois ou mais jogadores obtenham a mesma pontuação no ranking, o melhor colocado será aquele que houver se cadastrado primeiro no jogo.</p>

                <p>Boa sorte! ;)</p>

            </div>

        if (!isMobile) {
            scroll =
                <Scrollbar alwaysShowTracks={true} plugins={new Object()}>
                    {scroll}
                </Scrollbar>
        }

        return (
            <div className="como-jogar">
                <h2>Como Jogar</h2>
                <div className="scroll-container">
                    {scroll}
                </div>
                <div id="actions">
                    <span className={`button-next button-action`} onClick={this.selectSubject} rel='unique' >Avançar</span>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComoJogar));