// import React, { Component } from 'react';
import { Component } from 'react';

export default class Page extends Component{

    componentWillMount() {
        if( this.id ) document.body.id = this.id;
        this.unlisten = this.props.history.listen((location, action) => {
            if( this.id ){
                document.body.id = this.id;
            }else{
                document.body.id = '';
            }
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }
}