import detectDebbug from './log';
import * as firebase from 'firebase';
import moment from 'moment';

const userBasic = {
    points: 0,
    position: -1,
    name: 'no name',
    slug: detectDebbug ? 'no-name1' : 'no-name',
};

const resultBasic = {
    title: 'no name',
    login: 'no-name',
    points: 0,
    time: 0
};

const app = firebase.initializeApp({
  apiKey: "AIzaSyD-QSiQO94S-DnugnX4LUVsEVL5sVLdQ5w",
  authDomain: "senac-quiz.firebaseapp.com",
  databaseURL: "https://senac-quiz.firebaseio.com",
  projectId: "senac-quiz",
  storageBucket: "senac-quiz.appspot.com",
  messagingSenderId: "528974619017",
  appId: "1:528974619017:web:463bdb78c41572100b4158",
  measurementId: "G-7L7VBQXT8P"
});

const db = firebase.firestore();

export default {
    listMain: 'SegInfo - Game',
    listHistory: 'SegInfo - GameHistorico',
    typeMain: 'Aldeia',
    typeHistory: 'AldeiaHistorico',
    //
    _digest: null,
    apiAbsoluteUrl () {
        return '/sites/seginfo';
    },
    getDigest () {
        return this._digest || (document.getElementById('__REQUESTDIGEST') || { value: 'not-defined' }).value;
    },
    pre (callback) {
        callback();
        return this;
    },
    setCurrentLoginState (logged) {
        if(logged) {
            const now = Date.now();
            localStorage.setItem('quiz-login', now);
        } else {
            localStorage.removeItem('quiz-login');
        }
    },
    getCurrentLoginState () {
        const lastLogin = localStorage.getItem('quiz-login');
        const maxLoginTime = 1000 * 60 * 60; // 1 hora
        const hasLastTime = !!lastLogin;
        const now = Date.now();
        const isLogged = hasLastTime && ( ( now - parseFloat(lastLogin) ) < maxLoginTime );
        return isLogged;
    },
    getContext () {
        let self = this;
        const url = this.apiAbsoluteUrl() + "/_api/contextinfo";
        return fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "Accept": "application/json;odata=verbose"
            }
        })
            .then(function (response) {
                var contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    return response.json();
                }
                throw new TypeError("Oops, we haven't got JSON!");
            })
            .then(function (response) {
                self._digest = response.d.GetContextWebInformation.FormDigestValue;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    createUser (userData){
        if(!userData.cpf) return Promise.resolve(false);

        return  db
            .collection("users")
            .doc(userData.cpf)
            .set({ ...userBasic, ...userData })
            .then(() => {
                return { ...userBasic, ...userData };
            });
    },
    getUser (login) {
        const token = login || localStorage.getItem('token');
        if(!token) return Promise.resolve(false);

        return db
                .collection("users")
                .where("cpf", "==", token)
                .get()
                .then((querySnapshot) => {
                    const exists = !querySnapshot.empty;
                    return exists ? { ...userBasic, ...querySnapshot.docs[0].data()} : false;
                });

    },
    getPlayers () {
        return db
                .collection("users")
                .get()
                .then((querySnapshot) => {
                    const exists = !querySnapshot.empty;
                    return exists ? querySnapshot.docs.map(p => p.data()) : false;
                });
    },
    getRanking (momentDate) {
        if (!momentDate) {
            momentDate = moment();
        }
        const start = momentDate.startOf('day').valueOf();
        const end = momentDate.endOf('day').valueOf();

        return db
                .collection("ranking")
                .where('savedAt', '>', start)
                .where('savedAt', '<', end)
                .get()
                .then((querySnapshot) => {
                    const exists = !querySnapshot.empty;
                    return exists ? querySnapshot.docs.map(p => p.data()) : false;
                });
    },
    getPlayerInTodayRanking (userKey) {
        if(!userKey) return Promise.resolve(false);
        const dateKey = moment().format('DD-MM-YYYY');
        const key = `${userKey}-${dateKey}`;

        return db
                .collection("ranking")
                .doc(key)
                .get()
                .then((querySnapshot) => {
                    const exists = querySnapshot.exists;
                    return exists ? querySnapshot.data() : false;
                })
                .catch((error) => {
                    console.error(error)
                    return false;
                });
    },
    async saveResult (userData, result) {
        if(!userData.logged) return Promise.resolve(false);
        const dateKey = moment().format('DD-MM-YYYY');
        const userKey = userData.cpf;
        const key = `${userKey}-${dateKey}`;

        const currentResult = await this.getPlayerInTodayRanking(userKey);

        const { isAdmin, logged, position, id, ...userSavedData } = userData;
        const data = {
            ...resultBasic,
            ...userSavedData,
            ...result,
            savedAt: Date.now()
        };

        if( currentResult && currentResult.points > data.points ){
            return Promise.resolve(currentResult);
        }

        return  db
            .collection("ranking")
            .doc(key)
            .set(data)
            .then(() => {
                return data;
            });
    },
    createPlayedSession (values) {
        return Promise.resolve();
    },
};