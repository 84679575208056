import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Scrollbar from 'smooth-scrollbar-react';
import Page from '../components/page';
import Input from '../components/input';
import TYPES from '../constants/action-types';
import { AuthContext } from '../contexts/auth';

import '../css/cadastro.css';


function mapStateToProps (state) {
  return {
    anwsers: state.anwsers,
    test: state.test
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onSubjectChoice: id => {
      let subject = id;
      dispatch({ type: TYPES.SET_SUBJECT, payload: subject });
      return Promise.resolve();
    },
    onSetUser: user => {
      dispatch({ type: TYPES.SET_USER, payload: user });
      return Promise.resolve();
    }
  }
}
class Cadastro extends Page {

  constructor (props) {
    super(props);
    this.id = 'cadastro';
    this.state = {
      name: '',
      cpf: '',
      email: '',
      error: ''
    };
    this.trySingin = this.trySingin.bind(this);
    this.handleName = this.handleName.bind(this);
  }

  handleName(evt) {
    let { value, maxLength } = evt.target;
    let name = value.slice(0, maxLength);
    this.setState({name});
  }

  testaCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11))  Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;

    Soma = 0;
      for (let x = 1; x <= 10; x++) Soma = Soma + parseInt(strCPF.substring(x - 1, x)) * (12 - x);
      Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11))  Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
      return true;
  }

  trySingin(e, createUser) {
    e.preventDefault();
    const { name, cpf: icpf, email } = this.state;
    const cpf = (icpf || '').replace(/[\D_]/g, '');
    const slug = name.toLowerCase().replace(/[^a-z]/gi, '-');
    this.setState({error:''});

    if( !name ) return this.setState({error: 'Nome é obrigatório'});
    if( !email ) return this.setState({error: 'Email é obrigatório'});
    if( !(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/).test(email) ) return this.setState({error: 'Email inválido'});
    if( !cpf ) return this.setState({error: 'CPF é obrigatório'});
    if( !this.testaCPF(cpf) ) return this.setState({error: 'CPF inválido'});
    if( !(/^\d{11}/).test(cpf) ) return this.setState({error: 'CPF inválido'});

    createUser({ name, cpf, email, slug })
      .then(({user, next}) => {
        this.props.onSubjectChoice('unique');
        this.props.onSetUser(user);
        setTimeout(() => {
          this.props.history.replace('/como_jogar');
        }, 250);
        next();
      })
      .catch(error => {
        this.setState(error)
      });
  }

  render () {
    const isMobile = window.innerWidth < 720;

    let scroll =
      <div className="list-data" style={{ height: '100%', width: '100%', overflow: 'auto' }}>
        <Input label="Nome e Sobrenome" value={this.state.name} onChange={this.handleName} maxLength="20"/>
        <Input label="E-mail" onChange={evt=>this.setState({email: evt.target.value})} />
        <Input label="CPF" onChange={evt=>this.setState({cpf: evt.target.value})} />
        <span className="erro">{this.state.error}</span>
      </div>

    if (!isMobile) {
      scroll =
        <Scrollbar alwaysShowTracks={true} plugins={new Object()}>
          {scroll}
        </Scrollbar>
    }

    return (
      <div className="cadastro">
        <h2>Cadastro</h2>
        <p className="lead">Antes de começar a jogar, preencha<br /> corretamente os seus dados.</p>

        <AuthContext.Consumer>
          {({createUser, loading}) => (
            <>
              <div>
                {scroll}
                {loading && <div>Carregando</div>}
              </div>
              <div id="actions">
                <Link className={`button-back button-action`} to={`/logar`} replace >Voltar</Link>
                <a className={`button-next button-action`} onClick={e => this.trySingin(e, createUser)} >Avançar</a>
              </div>
            </>
          )}
        </AuthContext.Consumer>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cadastro));