// import React, { Component } from 'react';
import Page from './page';

class casePage extends Page{

    getCurrentCase(){
        return this.props.cases[ this.props.current_case_id || 0 ];
    }

    getCurrentQuestion(){
        let questionId = this.props.current_question_id;
        return this.props.cases[ this.props.current_case_id ].perguntas[ questionId || 0 ];
    }

    isLastCase(){
        return ( this.props.current_case_count === this.props.max_case_count - 1 );
    }

    isLastQuestion(){
        return ( this.props.current_question_count === this.props.max_question - 1 );
    }

    isLastSubject(){
        return ( this.props.subjects.filter((value)=>{ return !value.viewed; }).length === 0 );
    }

    componentWillMount(){
        super.componentWillMount();
        // let hasCurrentCase = this.props.current_case_count !== undefined;
        // let hasMaxCase = this.props.max_case_count !== undefined;
        // let caseEnded = hasCurrentCase && hasMaxCase && ( this.isLastCase() );
        // let hasSubjects = this.props.subjects !== undefined;
        // let subjectsEnded = hasSubjects && ( this.isLastSubject() );

        // check if current case ended
        /*if( caseEnded ){
            if( subjectsEnded ) {
                this.props.history.replace('/resultados');
            }else{
                this.props.history.replace('/assunto');
            }
        }*/
    }
}

export default casePage;