// BLOCK console function if is not on localhost

function detectDebbug(){
    const isLocalhost = window.location.href.indexOf('http://localhost') > -1 || window.location.href.indexOf('http://127.0.0.1') > -1;
    const forcedDebbug = localStorage.getItem('debbug');

    return ( isLocalhost || forcedDebbug );
};

if( !detectDebbug() ){
    console.log = function(){}
    console.error = function(){}
    console.warn = function(){}
}

export default detectDebbug;