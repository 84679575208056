import React, { Component } from 'react';

class Suspeito extends Component{

    trySelect(){
        const components_id = 'suspect' + this.props.id;
        if( !this.props.disabled ){
            this.props.onClick(components_id);
        }
    }

    render(){
        const selected = this.props.selected ? 'selected' : '';
        const disabled = this.props.disabled ? 'disabled' : '';
        const suspectSlug = this.props.slug;
        const result = disabled ? ( this.props.correct ? 'right' : 'wrong' ) : '';
        const className = ([ suspectSlug, selected, disabled, result]).filter((c)=>c).join(" ");

        return(
            <li className={className} onClick={()=>this.trySelect()} disabled={this.props.disabled} >{this.props.correct}</li>
        );
    }
}

export default Suspeito;