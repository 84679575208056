import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import React, { Component } from 'react';
import React from 'react';
import TYPES from "../constants/action-types";
import '../css/responder.css';
import '../css/animate.css';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Link } from "react-router-dom";

import casePage from '../components/casePage';
import Timer from "../components/timer";
import Answer from "../components/answer";
import Suspect from "../components/suspect";

function mapStateToProps (state) {
    return {
        cases: state.cases,
        current_case_id: state.current_case_id,
        current_case_count: state.current_case_count,
        max_case_count: state.max_case_count,
        current_question: state.current_question,
        current_question_count: state.current_question_count,
        max_question: state.max_question,
        current_question_id: state.current_question_id,
        subjects: state.subjects
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSubmitAnswerClick: id => {
            dispatch({ type: TYPES.SUBMIT_ANSWER, payload: id });
            return Promise.resolve();
        },
        onAskNextCaseClick: id => {
            dispatch({type: TYPES.NEXT_CASE, payload:id});
            return Promise.resolve();
        }
    }
}

class Respostas extends casePage {

    constructor (props) {
        super(props);
        this.id = 'respostas';
        this.state = {
            case_id: this.getCurrentCase().id,
            selected: this.getCurrentCase().answer,
            suspect: this.getCurrentCase().suspect,
            answered: false
        };
    }

    onSetAnswerClick (index) {
        this.setState({ selected: index });
    }

    onSetSuspectClick (index) {
        this.setState({ suspect: index });
    }

    onSubmitAnswerClick () {
        let answer = {
            answer: this.state.selected,
            suspect: this.state.suspect
        };

            this.props.onSubmitAnswerClick(answer).then(() => {
                let lastCase = this.isLastCase();
                if (!lastCase) {
                    this.props.onAskNextCaseClick().then(()=>{
                        this.props.history.replace('/caso');
                    });
                }
                this.props.history.replace('/resultados');
            });

    }

    render () {
        // const kase = this.getCurrentCase();
        const question = this.getCurrentQuestion();

        const correct_answer = question.gabarito;
        const correct_suspect = question.culpado;

        const components_id = 'question_' + question.id;
        const fullAnswered = this.state.selected === undefined || this.state.suspect === undefined;
        const buttonDisabled = fullAnswered ? 'button-disabled' : 'animated shake';
        const optionsDisabled = this.state.answered;
        const backDisabled = optionsDisabled ? 'button-disabled' : '';
        let labelButton = this.state.answered ? 'Solução do caso' : 'Confirmar';

        return (
            <div id={components_id} className="respostas">
                <h2 className="text-left">{question.pergunta}</h2>
                <ul className="respostas">
                    {question.respostas.map((text, index) => {
                        return <Answer correct={index === correct_answer} disabled={optionsDisabled} selected={index === this.state.selected} key={index} index={index} onClick={() => this.onSetAnswerClick(index)} text={text} ></Answer>;
                    })}
                </ul>
                <ul className="suspeitos">
                    {question.suspeitos.map((text, index) => {
                        return <Suspect correct={text === correct_suspect} disabled={optionsDisabled} selected={text === this.state.suspect} key={index} index={index} onClick={() => this.onSetSuspectClick(text)} text={text} slug={text} ></Suspect>;
                    })}
                </ul>
                <div id="actions">
                    <Link className={`button-back button-action ` + backDisabled} to={`/Pistas`} replace >Voltar</Link>
                    <span className={`button-submit button-action ` + buttonDisabled} onClick={() => this.onSubmitAnswerClick()}>{labelButton}</span>
                    <Timer disabled={optionsDisabled}></Timer>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Respostas));