import React, { createContext, useCallback, useState } from 'react';
import apiService from '../utils/apiService';

export const AuthContext = createContext({});

export default function Auth ({children}) {
  const [ logged, setLogged ] = useState(() => apiService.getCurrentLoginState());
  const [ loading, setLoading ] = useState(false);

  const updateLoggedState = (login) => {
    setLogged(login);
    apiService.setCurrentLoginState(login);
    setLoading(false);
  }

  const login = useCallback((cpf) => 
    apiService
      .pre(() => setLoading(true))
      .getUser(cpf)
      .then((user) => {
        return user ?
          {user, next:() => updateLoggedState(true) } :
          {user: false, next:() => updateLoggedState(false) } ;
      }
  ), []);

  const singin = useCallback((user) => 
    apiService
      .pre(() => setLoading(true))
      .createUser(user)
      .then((user) => {
        return user ?
          {user, next:() => updateLoggedState(true) } :
          {user: false, next:() => updateLoggedState(false) } ;
      }
  ), []);

  return <AuthContext.Provider
    value={{
      logged,
      loading,
      setLogged: login,
      createUser: singin
    }}
  >
    {children}
  </AuthContext.Provider>;
}