import React, { Component } from 'react';
import Parser from 'html-react-parser';

class Dica extends Component{

    render(){
        let components_id = 'tip' + this.props.id;
        let className = this.props.selected ? 'selected' : 'not-selected' ;

        return(
            <li className={className} onClick={()=>this.props.onClick(components_id)}>
                <div className="frente">
                    <span>{Parser(this.props.frente)}</span>
                </div>
                <div className="verso">
                    <span>{Parser(this.props.verso)}</span>
                </div>
            </li>
        );
    }
}

export default Dica;