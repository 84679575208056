import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import TYPES from "../constants/action-types";
import Page from "../components/page";
import '../css/home.css';


function mapStateToProps (state) {
    return {
        current_case_count: state.current_case_count,
        max_case_count: state.max_case_count,
        user: state.user,
        playMusic: state.playMusic,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onPlay: (onOff) => {
            dispatch({ type: TYPES.PLAY_MUSIC, payload: onOff });
            return Promise.resolve();
        }
    }
}

class Home extends Page {

    constructor (props) {
        super(props);
        this.id = 'home';
    }

    nextPage = () => {
        const sound_track = document.getElementById("sound_track");

        if (sound_track) {
            const isOn = sound_track.currentTime === 0 ? false : true;

            this.props.onPlay(!isOn)
                .then(() => {
                    this.props.history.replace('/logar');
                });
        }
    }

    render () {

        return (
            <div>
                <h2>Segurança da Informação também é com você!</h2>
                <p className="lead">A partir de agora, você é o detetive e precisará apurar os incidentes de segurança da informação do Senac.</p>
                <img src={require('../img/ilustracao-home-mobile.png')} />
                <div id="actions">
                    <span
                        className={`button-play button-action`}
                        onClick={this.nextPage}>
                        Como Jogar
                    </span>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));