import { withRouter } from 'react-router-dom';
import Parser from 'html-react-parser';
import {connect} from 'react-redux';
import React from 'react';
import TYPES from "../constants/action-types";
import casePage from '../components/casePage';
import '../css/caso_resultado.css';

function mapStateToProps(state){
    return state;
}

const mapDispatchToProps = dispatch => {
    return {
        onAskNextCaseClick: id => {
            dispatch({type: TYPES.NEXT_CASE, payload:id});
            return Promise.resolve();
        },
    }
}

class Respostas extends casePage{

    constructor(props){
        super(props);
        this.id = 'caso_resultado';
    }

    onNextClick(){
        this.props.onAskNextCaseClick().then(()=>{
            this.props.history.replace('/caso');
        });
    }

    onNextClick(){
        let lastSubject = this.isLastSubject();
        let lastCase = this.isLastCase();
        if( !lastSubject || !lastCase ){
            if( !lastCase ){
                this.props.onAskNextCaseClick().then(()=>{
                    this.props.history.replace('/caso');
                });
            }else{
                this.props.history.replace('/assunto');
            }
        }else{
            this.props.history.replace('/resultados');
        }

    }

    render(){
        const kase = this.getCurrentCase();
        const question = this.getCurrentQuestion();

        return(
            <div className="caso-resultado">
                <div id="content">
                    <h2 className="text-left">Caso Solucionado</h2>
                </div>
                <div className="content">
                    <div className="left">
                        <div className="comportamento">
                            <h3>Deslize</h3>
                            <p>{Parser(kase.solucao.wrong_behaivor)}</p>
                        </div>
                        <div className="proxima_vez">
                            <h3>Na próxima vez...</h3>
                            <p>{Parser(kase.solucao.next_time_behaivor)}</p>
                        </div>
                    </div>
                    <div className="right">
                        <div className={question.culpado}></div>
                    </div>
                </div>
                <div id="actions">
                    <span className={`button-next button-action`} onClick={()=>this.onNextClick()}>Avançar</span>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Respostas));
