import update from 'immutability-helper';
import TYPES from "../constants/action-types";
import data from "../data/data";
import "../utils/shuffleArray";
const initialState = {
    user: {
        id: 0,
        logged: false,
        name: 'no name',
        slug: 'no-name',
        points: 0,
        position: 1000,
        cpf: '00000000000',
        isAdmin: false
    },
    pointsUpdated: false,
    // general
    currentTip: null,
    playMusic: false,
    test: 'teste',
    // time
    time: 0,
    timer_pause: true,
    // subjects
    subjects: data.subjects.map(sub => ({ ...sub, viewed: false})),
    subject: null,
    // cases
    cases: data.casos,
    cases_id_list: [],
    current_case_id: 0,
    current_case_count: 0,
    max_case_count: 5,
    // questions
    answers: [],
    questions_id_list: [],
    current_question_count: 0,
    current_question_id: 0,
    max_question: 1,
    // points
    points: 0
};

const randomCases = (cases, subject, limit = 1) => {
    let casesByType = {},
        casesId = [],
        selectedArray = [];

    cases.forEach(element => {
        if (!casesByType[ element.subject ]) casesByType[ element.subject ] = [];
        casesByType[ element.subject ].push(element.id);
        casesId.push(element.id);
    });

    if (casesByType[ subject ] && casesByType[ subject ].length) {
        selectedArray = casesByType[ subject ].shuffle();
        if (casesByType[ subject ].length > limit) {
            selectedArray = selectedArray.slice(0, limit);
        }
    }

    return selectedArray;
}

const randomQuestions = (questions) => {
    let questionsIds = [];

    questions.forEach(question => {
        questionsIds.push(question.id);
    });

    return questionsIds.shuffle();
}

const calculatePoints = (answers, time) => {
    let points = 0;

    answers.forEach((answer) => {
        points += answer.resultAnswer ? 10000 : 0;
        points += answer.suspectAnswer ? 10000 : 0;
    });
    points -= time;

    return Math.max(points, 0);
}

const rootReducer = (state = initialState, action) => {
    let selected_subject,
        selected_subject_index,
        cases_id_list,
        next_case_count,
        next_case_id,
        new_questions,
        next_question_id,
        next_question_count,
        rand_questions_ids,
        current_points,
        updateObj;

    switch (action.type) {
        case TYPES.SET_USER:
            return update(state, {
                user: {
                    name: { $set: action.payload.name },
                    slug: { $set: action.payload.slug },
                    cpf: { $set: action.payload.cpf },
                    isAdmin: { $set: action.payload.isAdmin },
                    logged: { $set: true }
                }
            });
        case TYPES.LOG_OFF:
            return Object.assign({}, state, { ...initialState });
        case TYPES.PLAY_MUSIC:
            return update(state, {
                playMusic: { $set: false }
            });
        case TYPES.RESET:
            const prevState = state;
            return Object.assign({}, state, { ...initialState, user: prevState.user });
        case TYPES.RANDOM_CASES:
            cases_id_list = randomCases(state.cases, action.payload);
            return Object.assign({}, state, {
                cases_id_list
            });
        case TYPES.DO_TEST:
            return Object.assign({}, state, {
                test: action.payload
            });
        case TYPES.SET_SUBJECT:
            selected_subject = action.payload;
            state.subjects.map((value, index) => {
                if (value.id === selected_subject) selected_subject_index = index;
            });

            cases_id_list = randomCases(state.cases, selected_subject, state.max_case_count);
            next_case_count = 0;
            next_case_id = cases_id_list[ next_case_count ];

            new_questions = state.cases[ next_case_count ].perguntas;
            rand_questions_ids = randomQuestions(new_questions);
            next_question_count = state.current_question_count;
            next_question_id = rand_questions_ids[ next_question_count ];

            return update(state, {
                subject: { $set: selected_subject },
                subjects: { [ selected_subject_index ]: { viewed: { $set: true } } },
                cases_id_list: { $set: cases_id_list },
                current_case_count: { $set: 0 },
                current_case_id: { $set: next_case_id },
                questions_id_list: { $set: rand_questions_ids },
                current_question_count: { $set: 0 },
                current_question_id: { $set: next_question_id }
            });
        // TIPS
        case TYPES.SHOW_TIP:
            let nxtStatus = true;

            if (state.cases[ state.current_case_id ].pistas[ action.payload ].status) {
                nxtStatus = false;
            }

            return update(state, {
                cases: {
                    [ state.current_case_id ]: {
                        pistas: {
                            [ action.payload ]: {
                                status: {
                                    $set: nxtStatus
                                }
                            }
                        }
                    }
                }
            });
        // ANSWERS
        case TYPES.SUBMIT_ANSWER:
            const answerSelected = action.payload.answer;
            const suspectSelected = action.payload.suspect;

            const resultAnswer = state.cases[ state.current_case_id ].perguntas[ state.current_question_id ].gabarito === answerSelected;
            const suspectAnswer = state.cases[ state.current_case_id ].perguntas[ state.current_question_id ].culpado === suspectSelected;
            const result = resultAnswer && suspectAnswer;

            const answer = {
                case_id: state.current_case_id,
                question_id: state.current_question_id,
                result: result,
                resultAnswer: resultAnswer,
                suspectAnswer: suspectAnswer,
                answer: answerSelected,
                suspect: suspectSelected
            };

            var answers = state.answers.slice(0);
            answers.push(answer);
            current_points = calculatePoints(answers, state.time);
            let updateObj = {
                answers: {
                    $push: [ answer ]
                },
                points: {
                    $set: (current_points)
                },
                user: {
                    points: {
                        $set: (current_points)
                    }
                }
            };

            return update(state, updateObj);
        // NEXT CASE
        case TYPES.NEXT:
            next_question_count = state.current_question_count + 1;
            const isNewCase = next_question_count >= state.max_question;
            action.type = isNewCase ? TYPES.NEXT_CASE : TYPES.NEXT_QUESTION;

            return rootReducer(state, action);
        case TYPES.NEXT_QUESTION:
            console.log("Próximo questão");

            next_question_count = state.current_question_count + 1;
            next_question_id = state.questions_id_list[ next_question_count ];

            updateObj = {};
            updateObj.current_question_count = { $set: (next_question_count) }
            updateObj.current_question_id = { $set: next_question_id }

            return update(state, updateObj);
        case TYPES.NEXT_CASE:
            console.log("Próximo caso");

            next_case_count = state.current_case_count + 1;
            next_case_id = state.cases_id_list[ next_case_count ];

            new_questions = state.cases[ next_case_count ].perguntas;
            rand_questions_ids = randomQuestions(new_questions);
            next_question_count = 0;
            next_question_id = rand_questions_ids[ next_question_count ];

            const newState = {};
            newState.current_case_count = { $set: (next_case_count) }
            newState.current_case_id = { $set: (next_case_id) }
            newState.questions_id_list = { $set: rand_questions_ids }
            newState.current_question_count = { $set: (next_question_count) }
            newState.current_question_id = { $set: next_question_id }

            return update(state, newState);
        // TIME
        case TYPES.UPDATE_POINTS:
            current_points = calculatePoints(state.answers, state.time);

            return update(state, {
                points: {
                    $set: (current_points)
                },
                user: {
                    points: {
                        $set: (current_points)
                    }
                },
                pointsUpdated: {
                    $set: true
                }
            });
        case TYPES.SET_TIME:
            return Object.assign({}, state, {
                time: !state.timer_pause ? action.payload : state.time
            });
        case TYPES.CHANGE_TIMER_STATUS:
            return Object.assign({}, state, {
                timer_pause: action.payload
            });
        default:
            return state;
    }
};

export default rootReducer;