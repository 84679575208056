import React, { Component } from 'react';
import { connect } from 'react-redux';
import TYPES from "../constants/action-types";
import song from '../audio/bensound-ukulele.mp3';

function mapStateToProps (state) {
    return {
        playMusic: state.playMusic,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onPlayMusic: (onOff) => {
            dispatch({ type: TYPES.PLAY_MUSIC, payload: onOff });
            return Promise.resolve();
        }
    }
}

class Audio extends Component {
    onPlayStop () {
        const sound_track = document.getElementById("sound_track");

        if (sound_track) {
            const isOn = sound_track.currentTime === 0 ? false : true;

            this.props.onPlayMusic(!isOn)
                .then(() => {
                    if (this.props.playMusic) {
                        sound_track.play();
                    } else {
                        sound_track.pause();
                        sound_track.currentTime = 0;
                    }
                });
        }
    }

    render () {
        this.onPlayStop();
        return <div>
            {/* <span className="botao-audio" onClick={() => { this.onPlayStop(); }}></span> */}
            <audio id="sound_track" loop={true} volume="0.3">
                <source src={song} />
            </audio>
        </div>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Audio);