import React, { Component } from 'react';
import Parser from 'html-react-parser';

class Gabarito extends Component {

    randId () {
        return Math.floor(Math.random() * 1000);
    }

    acertou(index, pergunta) {
        return <div className="acerto" key={index}>
        <div className="titulo">
            <h4>Pergunta {pergunta}:</h4><span className="label">Acertou</span>
        </div>
    </div>
    }

    errou(index, pergunta) {
        return <div className="erro" key={index}>
            <div className="titulo">
                <h4>Pergunta {pergunta}:</h4><span className="label">Errou</span>
            </div>
        </div>
    }

    explicacao(description) {
        return <div className="explicacao">
            <h4>Explicação:</h4>
            <div>
                {Parser(description)}
            </div>
        </div>
    }

    render () {
        // let components_id = 'gabarito_' + ( this.props.id || this.randId() );
        let className = this.props.selected ? 'selected' : '';
        let count = this.props.count;
        let {
            questions,
            case: kase
        } = this.props.data;

        return (
            <li className={className}>
                <h3>Caso {count + 1}</h3>
                <div className="gabarito-content">
                    {(questions).map((question, index) => {
                        return <>
                            {question.resultAnswer ? this.acertou(index, 1) : this.errou(index, 1)}
                            {question.suspectAnswer ? this.acertou(index, 2) : this.errou(index, 2)}
                            {this.explicacao(question.description)}
                        </>
                    })}
                </div>
            </li>
        );
    }
}

export default Gabarito;