const types = {
    'LOGIN_USER': 'login_user',
    'SET_USER': 'set_user',
    'LOG_OFF': 'log_off',
    'ADD_ANSWER': 'add_answer',
    'DO_TEST': 'do_test',
    'SET_SUBJECT': 'set_subject',
    'SHOW_TIP': 'show_tip',
    'SET_TIME': 'set_time',
    'CHANGE_TIMER_STATUS': 'change_timer_status',
    'SET_CASE': 'set_case',
    'SUBMIT_ANSWER': 'submit_answer',
    'UPDATE_POINTS': 'update_points',
    'NEXT': 'next',
    'NEXT_QUESTION': 'next_question',
    'NEXT_CASE': 'next_case',
    'RESET': 'reset',
    'PLAY_MUSIC': 'play_music'
}

export default types;