export default {
    save(state){
        try{
            const serializedState = JSON.stringify(state);
            localStorage.setItem('globo-quiz', serializedState);
        }catch(err){
            return undefined;
        }
    },
    load(){
        try{
            const serializedState = localStorage.getItem('globo-quiz');
            if(serializedState === null || serializedState === "{}"){
                return undefined;
            }
            return JSON.parse(serializedState);
        }catch(err){
            return undefined;
        }

    }
}