import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import React, { Component } from 'react';
import React from 'react';
import TYPES from "../constants/action-types";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// import { Link } from "react-router-dom";
import '../css/assunto.css';
import '../css/animate.css';
import '../css/transitions.css';

import casePage from '../components/casePage';

function mapStateToProps (state) {
    return {
        subject: state.subject,
        subjects: state.subjects,
        cases_id_list: state.cases_id_list
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSubjectClick: id => {
            let subject = id;
            dispatch({ type: TYPES.SET_SUBJECT, payload: subject });
            return Promise.resolve();
        }
    }
}

class Home extends casePage {

    constructor (props) {
        super(props);
        this.id = 'assunto';

        this.selectSubject = this.selectSubject.bind(this);
        this.state = {
            disabled: false
        };
    }

    selectSubject (e) {
        let enabled = !e.currentTarget.classList.contains('disabled');
        let selectedSubject = e.currentTarget.attributes.rel.value;

        if (enabled) {
            this.props.onSubjectClick(selectedSubject)
                .then(() => {
                    this.setState({ disabled: true });
                    setTimeout(() => {
                        this.setState({ disabled: false });
                        this.props.history.replace('/caso');
                    }, 550);
                });
        }
    }

    render () {
        // const subject = this.props.subject;
        // const buttonDisabled = subject === null || this.props.cases_id_list.length === 0 ? 'button-disabled' : 'animated shake';
        // const ids = this.props.cases_id_list;
        const assuntos = this.props.subjects;
        const forceDisable = this.state.disabled;

        return (
            <div className="assunto">
                <div className='left'>
                    <h2 className="text-left">Escolha o Ambiente</h2>
                    <p className="text-left">Escolha um local dos <strong>Estúdios Globo.</strong><br />Para concluir a sua jogada, você deverá passar por todos os ambientes.</p>
                </div>
                <div className='right'>
                    <ul>
                        {assuntos.map((assunto, index) => {
                            let selectStatus = assunto.viewed ? 'disabled' : '';
                            let className = selectStatus;
                            return <li key={index} id={'assunto-' + index} className={assunto.id + ' ' + className} rel={assunto.id} onClick={this.selectSubject} disabled={assunto.viewed || forceDisable}><span>{assunto.title}</span></li>;
                        })}
                    </ul>
                </div>
                <div id="actions">
                    {/* <Link className={`button-play ` + buttonDisabled} to={`/caso`} replace >Jogar</Link> */}
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));