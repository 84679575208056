import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Scrollbar from 'smooth-scrollbar-react';
import Page from '../components/page';
import Input from '../components/input';
import TYPES from '../constants/action-types';
import { AuthContext } from '../contexts/auth';

import '../css/logar.css';

function mapStateToProps (state) {
  return {
    anwsers: state.anwsers,
    test: state.test
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onSubjectChoice: id => {
      let subject = id;
      dispatch({ type: TYPES.SET_SUBJECT, payload: subject });
      return Promise.resolve();
    },
    onSetUser: user => {
      dispatch({ type: TYPES.SET_USER, payload: user });
      return Promise.resolve();
    }
  }
}

class Logar extends Page {

  constructor (props) {
    super(props);
    this.id = 'logar';

    this.state = {
      login: '',
      error: ''
    };
    this.tryLogin = this.tryLogin.bind(this);
  }

  tryLogin(e, setLogged) {
    e.preventDefault();
    this.setState({error:''});

    setLogged(this.state.login.replace(/[\D_]/g, ""))
      .then(({user, next}) => {
        if(user){

          this.props.onSubjectChoice('unique');
          this.props.onSetUser(user);
          next();
          setTimeout(
            () => this.props.history.push('/como_jogar'),
          100);
        }else{
          this.setState({
            error : 'Erro ao logar.'
          });
        }
      })
      .catch(error => {
        console.log(error.message);
      });
  }

  render () {
    const isMobile = window.innerWidth < 720;

    let scroll =
      <div>
        <Input label="CPF" onChange={evt=>this.setState({login: evt.target.value})} />
        <span className="error">{this.state.error}</span>
      </div>

    if (!isMobile) {
      scroll =
        <Scrollbar alwaysShowTracks={true} plugins={new Object()}>
          {scroll}
        </Scrollbar>
    }

    return (
      <div className="cadastro">
        <h2>Login</h2>
        <p className="lead">Se você já é cadastrado no game,<br/>preencha seu CPF e clique em “Jogar”.<br/>Se ainda não é, clique abaixo no botão "Cadastrar".</p>
        <AuthContext.Consumer>
          {({setLogged, loading}) => (
            <>
              <div>
                {scroll}
                {loading && <div>Carregando</div>}
              </div>
              <div id="actions">
                <Link className={`button-back button-action`} to={`/`} replace >Voltar</Link>
                <a className={`button-play button-action ${this.state.login ? '' : 'button-disabled'}`} onClick={e=>this.tryLogin(e, setLogged)} disabled={!this.state.login}>Jogar</a>
                <Link className={`button-next button-action`} to={`/cadastro`} replace>Cadastrar</Link>
              </div>
            </>
          )}
        </AuthContext.Consumer>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logar));