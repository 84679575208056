import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import React, { Component } from 'react';
import TYPES from "../constants/action-types";


function mapStateToProps(state){
    return{
        counter: state.time
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onTimeTick: (time) => {
            dispatch({type: TYPES.SET_TIME, payload: time})
        }
    }
}

class Timer extends Component{

    state = {
        max_timeout: 15 * 60 * 10,
        lastTime: 0,
    };

    componentDidMount() {
        this.setState({ lastTime: Date.now() });
        let timer = setInterval(()=>{
                const now = Date.now();
                if(!this.props.disabled){
                    this.setState((state) => {
                        const newTime = now - state.lastTime;
                        const newCounter = this.props.counter + newTime / 100;
                        if( this.props.counter > this.state.max_timeout ){
                            this.props.history.replace('/resultados');
                        }else{
                            this.props.onTimeTick(newCounter);
                        }
                        return { lastTime: now };
                    });
                }else{
                    this.setState({ lastTime: now });
                }
        }, 100);

        this.timer = timer;
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    getTimeAgo(){
        let sec = Math.floor( this.props.counter / 10 ) % 60;
        let min = Math.floor( this.props.counter / 600 ) % 60;
        // let hor = Math.floor( this.props.counter / 36000 ) % 60;
        let secStrn = sec < 10 ? '0' + sec : sec ;
        let minStrn = min < 10 ? '0' + min : min ;
        // let horStrn = hor < 10 ? '0' + hor : hor ;

        return [minStrn, secStrn].join(":");
    }

    render(){
        const time = this.getTimeAgo();
        const freezed = this.props.disabled ? "freezed" : "";
        return(
            <span className={`button-time button-disabled ` + freezed}>{time}</span>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Timer));