import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import React from 'react';
import TYPES from "../constants/action-types";
import Scrollbar from 'smooth-scrollbar-react';
import Gabarito from '../components/gabarito';
import Page from '../components/page';
import '../css/gabarito.css';

function mapStateToProps (state) {
    return {
        cases: state.cases,
        answers: state.answers
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdatePointsClick: id => {
            dispatch({ type: TYPES.UPDATE_POINTS })
        },
        onResetClick: id => {
            dispatch({ type: TYPES.RESET })
        }
    }
}

class GabaritoPage extends Page {

    constructor (props) {
        super(props);
        this.props.onUpdatePointsClick();
        this.id = 'gabarito';
    }

    resetGame () {
        this.props.onResetClick();
        this.props.history.replace('/default.aspx');
    }

    getAnswers () {
        var completeAnswers = [];
        var caseId = -1;

        this.props.answers.forEach((answer, index) => {
            let kase = this.props.cases[ answer.case_id ];
            let question = kase.perguntas[ answer.question_id ];

            if (kase.id !== caseId) {
                caseId = kase.id;
                completeAnswers.push({
                    case: kase,
                    questions: []
                });
            }
            answer.labelCase = kase.subjectLabel;
            answer.description = question.explicacao;

            completeAnswers[ completeAnswers.length - 1 ].questions.push(answer);
        });

        return completeAnswers;
    }

    render () {
        const isMobile = window.innerWidth < 720;
        const answers = this.getAnswers();

        let scroll =
            <div className="list-data" style={{ height: '100%', width: '100%', overflow: 'auto' }}>
                <ul className="gabaritos">
                    {(answers).map((data, index) => {
                        return <Gabarito key={index} count={index} data={data} ></Gabarito>;
                    })}
                </ul>
            </div>

        if (!isMobile) {
            scroll =
                <Scrollbar alwaysShowTracks={true} plugins={new Object()}>
                    {scroll}
                </Scrollbar>
        }

        return (
            <div className="gabarito">
                <div id="content">
                    <h2>Gabarito</h2>
                </div>
                <div className="content">
                    <div className="scroll-container">
                        {scroll}
                    </div>
                </div>
                <div id="actions">
                    <Link className={`button-back button-action`} to={`/resultados`} replace >Voltar</Link>
                    {/* <span className={`button-play`} onClick={()=>{ this.resetGame(); }} >Jogar</span> */}
                    <Link className={`button-ranking button-action`} to={`/ranking`} replace >Ranking</Link>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GabaritoPage));