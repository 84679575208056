import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TYPES from "../constants/action-types";
import Page from '../components/page';
import '../css/resultados.css';

function mapStateToProps (state) {
    return {
        cases: state.cases,
        current_case_id: state.current_case_id,
        current_case_count: state.current_case_count,
        max_case_count: state.max_case_count,
        points: state.points,
        user: state.user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdatePointsClick: id => {
            dispatch({ type: TYPES.UPDATE_POINTS })
        },
        onResetClick: id => {
            dispatch({ type: TYPES.RESET })
            return Promise.resolve();
        },
        onSubjectClick: subject => {
            dispatch({ type: TYPES.SET_SUBJECT, payload: subject });
            return Promise.resolve();
        },
        onLogOffClick: id => {
            dispatch({ type: TYPES.LOG_OFF });
            return Promise.resolve();
        }
    }
}

class Resultados extends Page {

    constructor (props) {
        super(props);
        this.id = 'resultados';
        this.props.onUpdatePointsClick();
    }

    getResultIllustration () {
        const result = [ 'excellent', 'good', 'bad' ];

        if (this.props.points > 75000) {
            return result[ 0 ];
        } else if (this.props.points > 50000) {
            return result[ 1 ];
        } else {
            return result[ 2 ];
        }
    }

    getResultText () {
        const result = [
            [ 'Parabéns! Segurança da informação é sua rotina.', 'Coloque em prática seus conhecimentos todos os dias.' ],
            [ 'Muito bem! Segurança da informação se pratica todo dia.', 'Inclua ela na sua rotina para ficar ainda melhor.' ],
            [ 'Que tal jogar outra vez? Segurança da informação é rotina.', 'Proteger as informações do Senac é simples e só depende de você. Pratique mais.' ],
        ];

        if (this.props.points > 75000) {
            return result[ 0 ];
        } else if (this.props.points > 50000) {
            return result[ 1 ];
        } else {
            return result[ 2 ];
        }
    }

    resetGame () {
        this.props.onResetClick().then(() => {
            this.props.onSubjectClick('unique').then(()=>{
                this.props.history.replace('/caso');
            });
        });
    }

    closeGame () {
        if (window.confirm("Deseja realmente sair do jogo?")) {
            this.props.onLogOffClick().then(()=>{
                localStorage.removeItem('quiz-login');
                window.location.href = 'https://www.senac.br/';
            });
        }
    }

    render () {
        const illustrationClass = 'illustration ' + this.getResultIllustration();
        const resultText = this.getResultText();
        const points = this.props.points.toLocaleString('pt-BR', { minimumFractionDigits: 0 });
        const btnRanking = (this.props.user.name || '').indexOf('_') >= 0 ? '' : (<Link className={`button-ranking`} to={`/ranking`} replace >Ranking</Link>)

        return (
            <div className="resultados">
                <div id="content">
                    <div className={illustrationClass} />
                    <h2>Você fez <span>{points}</span> pontos</h2>
                    <p><strong>{resultText[ 0 ]}</strong> {resultText[ 1 ]} </p>
                </div>
                <div id="actions">
                    <span className={`button-play button-action`} onClick={() => { this.resetGame(); }} >Jogar</span>
                    {btnRanking}
                    <Link className={`button-gabarito button-action`} to={`/gabarito`} replace >Gabarito</Link>
                    <span className={`button-sair button-action`} onClick={() => { this.closeGame(); }} >Sair</span>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Resultados));