import React, { Component } from 'react';
import Parser from 'html-react-parser';

class Resposta extends Component{

    trySelect(){
        const components_id = 'answer' + this.props.id;
        if( !this.props.disabled ){
            this.props.onClick(components_id);
        }
    }

    render(){
        const selected = this.props.selected ? 'selected' : '';
        const disabled = this.props.disabled ? 'disabled' : '';
        const result = disabled ? ( this.props.correct ? 'right' : 'wrong' ) : '';
        const className = ([selected, disabled, result]).filter((c)=>c).join(" ");

        return(
            <li className={className} onClick={()=>this.trySelect()} disabled={this.props.disabled} >
                <span>{Parser(this.props.text)}</span>
                <span className="signal"></span>
            </li>
        );
    }
}

export default Resposta;