import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Scrollbar from 'smooth-scrollbar-react';
import { Link } from "react-router-dom";
import TYPES from "../constants/action-types";
import apiService from "../utils/apiService";
import Page from '../components/page';
import moment from 'moment';

import '../css/ranking.css';

function mapStateToProps (state) {
    return {
        user: state.user,
        points: state.points,
        rankDate: state.rankDate
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onResetClick: id => {
            dispatch({ type: TYPES.RESET })
        }
    }
}

class Ranking extends Page {

    constructor (props) {
        super(props);
        this.state = {
            players: null,
            positionPlayer: 0,
            pointsPlayer: 0,
            rankDate: moment(),
        };
        this.id = "ranking";

        this.handleChange = this.handleChange.bind(this);
    }

    resetGame () {
        this.props.onResetClick();
        this.props.history.replace('/default.aspx');
    }

    orderDesc( a, b ) {
        if ( a.points < b.points ){
            return 1;
        }
        if ( a.points > b.points ){
            return -1;
        }
        return 0;
    }

    componentDidMount () {
        this.loadPlayers(this.state.rankDate);
    }

    handleChange(event) {
        let date = moment(event.target.value, "YYYY-MM-DD");
        this.setState({rankDate: date});
        this.loadPlayers(date);
    }

    loadPlayers (date) {
        this.setState({ players: null });
        apiService.getRanking(date).then((results) => {
            if (results) {
                let listPlayers = [];
                let isPlayer = false;
                let position = 0;
                let lastPoints = 0;

                results = results.sort( this.orderDesc );

                for (var i = 0; i < results.length; i++) {
                    var item = results[ i ];
                    let isLast = false;
                    let userID = this.props.user.cpf;

                    if (lastPoints != item.points) {
                        lastPoints = item.points;
                        position++;
                    }

                    if (position <= 20) {
                        listPlayers.push({
                            id: item.cpf,
                            name: item.name,
                            points: item.points,
                            position: position
                        });
                    } else {
                        isLast = true;
                    }

                    console.log(userID, item)
                    if (userID === item.cpf) {
                        this.setState({ positionPlayer: position });
                        this.setState({ pointsPlayer: item.points || 0 });
                        isPlayer = true;
                    }

                    // Forço a parda para não passar por todos desnecessariamente.
                    if (isPlayer && isLast) {
                        break;
                    }
                }
                this.setState({ players: listPlayers });
            } else {
                this.setState({ players: [] });
            }
        })
    }

    render () {
        const isMobile = window.innerWidth < 720;
        let players = this.state.players;
        let positionPlayer = this.state.positionPlayer;
        let namePlayer = this.props.user.name;
        let userIsAdmin = this.props.user.isAdmin;
        let pointsPlayer = this.state.pointsPlayer.toLocaleString('pt-BR', { minimumFractionDigits: 0 });
        let scrollClass = userIsAdmin ? 'scroll-container isAdminActive' : 'scroll-container';
        let dateRankFormated = this.state.rankDate.format('YYYY-MM-DD');

        let datePicker =
            <div className="content-admin"><input type="date" value={dateRankFormated} onChange={this.handleChange}/></div>;

        let adminPanel = userIsAdmin ? datePicker : '';

        let ranking;
        if (players !== null && players.length > 0) {
            ranking = (players).map((player, index) => {
                const name = player.name;
                const points = (player.points || 0).toLocaleString('pt-BR', { minimumFractionDigits: 0 });
                const position = (player.position || 0).toString().padStart(2, "0")
                return <div key={`${player.slug}-${index}` }>
                        <span className="star">{position}</span>
                        <span className="text"> {name} - <strong>{points} pts</strong></span>
                    </div>;
            })
        } else if (players !== null && players.length == 0) {
            ranking = <div className="loading">O Ranking está vazio para esta data.</div>
        } else {
            ranking = <div className="loading">Carregando...</div>
        }

        let scroll =
            <div className="list-data" style={{ height: '100%', width: '100%', overflow: 'auto' }}>
                <div className="gabarito-content">
                    {ranking}
                </div>
            </div>

        if (!isMobile) {
            scroll =
                <Scrollbar alwaysShowTracks={true} plugins={new Object()}>
                    {scroll}
                </Scrollbar>
        }

        return (
            <div className="ranking">
                <div id="content">
                    <h2>Ranking</h2>
                </div>
                {adminPanel}
                <div className="content">
                    <div className="caixinha-fofinha">
                        <div>
                            <span className="text-blue">{namePlayer}</span>, sua posição atual é <span className="text-blue">{positionPlayer.toString().padStart(2, "0")}</span>
                        </div>
                        <div>
                            Sua maior pontuação foi <span className="text-red">{pointsPlayer} Pts</span>
                        </div>
                    </div>
                    <div className={scrollClass}>
                        {scroll}
                    </div>
                </div>
                <div id="actions">
                    {/* <span className={`button-play`} onClick={()=>{ this.resetGame(); }} >Jogar novamente</span> */}
                    <Link className={`button-back button-action`} to={`/resultados`} replace >Voltar</Link>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ranking));