import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import React from 'react';
import TYPES from "../constants/action-types";
import casePage from '../components/casePage';
import Timer from "../components/timer";
import Tip from "../components/tip";
import '../css/ver_pistas.css';

function mapStateToProps (state) {
    return {
        cases: state.cases,
        current_case_id: state.current_case_id,
        current_case_count: state.current_case_count,
        max_case_count: state.max_case_count
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onTipClick: id => {
            dispatch({ type: TYPES.SHOW_TIP, payload: id })
        }
    }
}

class Pistas extends casePage {

    constructor (props) {
        super(props);
        this.id = 'pistas';
    }

    render () {
        const kase = this.getCurrentCase();
        const tips = kase.pistas;

        return (
            <div className="pistas">
                <ul className="pistas">
                    {tips.map((tip, index) => {
                        return <Tip selected={tip.status} key={index} index={index} onClick={() => this.props.onTipClick(index)} verso={tip.verso} frente={tip.frente} ></Tip>;
                    })}
                </ul>
                <div id="actions">
                    <Link className={`button-back button-action`} to={`/caso`} replace >Voltar</Link>
                    <Link className={`button-submit button-action`} to={`/responder`} replace >Responder</Link>
                    <Timer></Timer>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pistas));