import { log } from './utils/log'; // NÃO RETIRAR
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import logo from './img/logo.svg';
import logoSI from './img/logo-sebrae.svg';
import './css/reset.css';
import './css/fonts.css';
import './css/App.css';
import Home from './pages/home';
import Logar from './pages/logar';
import ComoJogar from './pages/como_jogar';
import Cadastro from './pages/cadastro';
import Assunto from './pages/assunto';
import Caso from './pages/caso';
import Pistas from './pages/ver_pistas';
import Resposta from './pages/responder';
import Resultado from './pages/resultados';
import Gabarito from './pages/gabarito';
import Ranking from './pages/ranking';
import RankingResumo from './pages/ranking_resumo';
import Solucao from './pages/caso_resultado';

import Main from './components/main';
import Audio from './components/audio';

import Auth, { AuthContext } from './contexts/auth';

class App extends Component {
  render () {
    return (
      <div className="App">
        <Router basename="/">
          <div>
            <header className="App-header">
              <img src={logo} className="App-logo" alt="logo" />
              <h1 className="App-title">Detetive - Segurança da informação</h1>
            </header>
            <Main>
              <div className="main-container">
                <Auth>
                  <AuthContext.Consumer>
                    {({logged}) => (
                      <Switch>
                        {!logged && <Route path="/logar" component={Logar} />}
                        {!logged && <Route path="/cadastro" component={Cadastro} />}

                        {logged && <Route path="/como_jogar" component={ComoJogar} />}
                        {logged && <Route path="/assunto" component={Assunto} />}
                        {logged && <Route path="/caso" component={Caso} />}
                        {logged && <Route path="/pistas" component={Pistas} />}
                        {logged && <Route path="/responder" component={Resposta} />}
                        {logged && <Route path="/solucao" component={Solucao} />}
                        {logged && <Route path="/resultados" component={Resultado} />}
                        {logged && <Route path="/gabarito" component={Gabarito} />}
                        {logged && <Route path="/ranking" component={Ranking} />}
                        {logged && <Route path="/ranking-resumo" component={RankingResumo} />}

                        {!logged && <Route path="/" exact component={Home} />}
                        {logged && <Route path="/" exact component={ComoJogar} />}
                        <Redirect to="/" />
                      </Switch>
                    )}
                  </AuthContext.Consumer>
                </Auth>
              </div>
              <div className="main-shadow"></div>
            </Main>
          </div>
        </Router>
        <footer>
          <img src={logoSI} className="App-logo" alt="Senac - Segurança da Informação" />
          <Audio />
        </footer>
      </div>
    );
  }
}

export default App;
