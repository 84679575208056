import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TYPES from "../constants/action-types";
import apiService from "../utils/apiService";

function mapStateToProps (state) {
    return {
        user: state.user,
        time: state.time,
        answers: state.answers,
        pointsUpdated: state.pointsUpdated
    };
}

function mapDispatchToProps (dispatch) {
    return {
        onLogin: (userData) => {
            return dispatch({ type: TYPES.SET_USER, payload: userData });
        },
        onReset: () => {
            return dispatch({ type: TYPES.RESET })
        },
        onUpdatePoints: id => {
            dispatch({ type: TYPES.UPDATE_POINTS });
            return Promise.resolve();
        }
    };
}

class Main extends Component {

    onLogin () {
        apiService.getUser().then((userData) => {
            this.props.onLogin(userData);
        });
    }

    componentDidUpdate (prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    componentDidMount () {
        let self = this;
        this.unlisten = this.props.history.listen((location, action) => {
            // RESET
            if (
                location.pathname === '/como_jogar' ||
                location.pathname === '/'
            ) {
                self.props.onReset();
            }

            // SAVE
            if (location.pathname === '/resultados') {
                this.props.onUpdatePoints().then(() => {
                    const isOutsourced = this.props.user.name.indexOf('_') >= 0 ? true : false

                    if (!isOutsourced) {
                        apiService.saveResult({
                            ...this.props.user
                        },
                        {
                            title: this.props.user.name,
                            login: this.props.user.slug,
                            points: this.props.user.points,
                            time: this.props.time
                        }).then(function () {
                            console.log("============ RANKING SAVED");
                        })
                    }

                    const answers = this.props.answers;
                    apiService.createPlayedSession({
                        login: this.props.user.slug,
                        points: this.props.user.points,
                        time: this.props.time,
                        caso: answers.map(c => c.case_id),
                        resposta: answers.map(c => c.resultAnswer),
                        responsavel: answers.map(c => c.suspectAnswer)
                    }).then(function () {
                        console.log("============ SESSION SAVED");
                    })
                });
            }

            if (!self.props.user.logged) {
                self.onLogin();
            }
            console.log("on route change");
        });
        //this.onLogin();
    }
    componentWillUnmount () {
        this.unlisten();
    }
    render () {
        return (
            <div className="main" data-user={this.props.user.name}>{this.props.children}</div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));