import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import Parser from 'html-react-parser';
import { connect } from 'react-redux';
import React from 'react';
import casePage from '../components/casePage';
import TYPES from "../constants/action-types";
import '../css/caso.css';
import Timer from "../components/timer";


function mapStateToProps (state) {
    return {
        cases: state.cases,
        current_case_id: state.current_case_id,
        current_case_count: state.current_case_count,
        max_case_count: state.max_case_count,
        subjects: state.subjects
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onStartPlayer: () => {
            dispatch({ type: TYPES.CHANGE_TIMER_STATUS, payload: false })
        }
    };
}

class Caso extends casePage {

    constructor (props) {
        super(props);
        this.id = 'caso';
        this.props.onStartPlayer();
    }

    render () {
        const kase = this.getCurrentCase();
        const question = this.getCurrentQuestion();
        const kaseCount = this.props.subjects.filter((subject) => (subject.viewed)).length + this.props.current_case_count;

        return (
            <div className="caso">
                <h2 className="text-left">Caso {kaseCount}</h2>
                <div className="description">
                    {(kase.descricao).map((line, index) => {
                        return <p key={index}>{Parser(line)}</p>;
                    })}
                </div>
                <div className="suspects">
                    {(question.suspeitos).map((suspect, index) => {
                        return <div key={index} className={suspect}>{suspect}</div>;
                    })}
                </div>
                <div id="actions">
                    <Link className={`button-pistas button-action`} to={`/pistas`} replace >Ver pistas</Link>
                    <Timer></Timer>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Caso));